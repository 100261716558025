import React, { Component } from "react"
import { graphql } from "gatsby"
import { Trans } from "gatsby-plugin-react-i18next"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Container from "../../components/Container"
import ArticleCard from "../../components/ArticleCard"
import ContactCard from "../../components/ContactCard"

import "./style.scss"

class InsightPage extends Component {
  render() {
    const { data } = this.props
    const { homepageBannerBg, insightData } = data

    return (
      <Layout>
        <SEO
          title="Insight"
          keywords={[
            `provalindonusa`,
            `consultant`,
            `advisory`,
            `properti`,
            `jakarta`,
            `insight`,
          ]}
          image={
            homepageBannerBg ? homepageBannerBg.childImageSharp.resize : null
          }
        />
        <div className="insightpage">
          <section className="insightpage__banner">
            <Container className="insightpage__banner-container">
              <h1>
                <Trans>Insight Title</Trans>
              </h1>
              <p>
                <Trans>Insight Description</Trans>
              </p>
            </Container>
          </section>

          <section className="insightpage__deep">
            <Container>
              <div className="insightpage__deep-list">
                {insightData.edges.map(item => {
                  return item.node.frontmatter.type === "Deep" ? (
                    <ArticleCard
                      key={item.node.id}
                      link={item.node.fields.slug}
                      date={item.node.frontmatter.date}
                      title={item.node.frontmatter.title}
                      description={item.node.excerpt}
                      cover={item.node.frontmatter.cover.childImageSharp.fluid}
                      isDeepArticle
                    />
                  ) : null
                })}
              </div>
            </Container>
          </section>

          <section className="insightpage__common">
            <Container>
              <div className="insightpage__common-list">
                {insightData.edges.map(item => {
                  return item.node.frontmatter.type === "Common" ? (
                    <ArticleCard
                      key={item.node.id}
                      link={item.node.fields.slug}
                      date={item.node.frontmatter.date}
                      title={item.node.frontmatter.title}
                      description={item.node.excerpt}
                      cover={item.node.frontmatter.cover.childImageSharp.fluid}
                    />
                  ) : null
                })}
              </div>
            </Container>
          </section>

          <section className="insightpage__contact">
            <Container>
              <ContactCard
                title="Contact Title"
                subtitle="Contact Description"
              />
            </Container>
          </section>
        </div>
      </Layout>
    )
  }
}

export default InsightPage

export const insightPageQuery = graphql`
  query {
    homepageBannerBg: file(
      relativePath: { eq: "images/homepage-banner-bg.png" }
    ) {
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
    insightData: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          templateKey: { eq: "article-page" }
          isShow: { eq: true }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            cover {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            description
            date(formatString: "DD MMMM, YYYY")
            type
            isTop
            isShow
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
